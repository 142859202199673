<script setup>
const imgList = ref([
    {
        img: '../../../assets/imgs/footer/ai.png',
        text:'AI-Driven'
    },
    {
        img: '../../../assets/imgs/footer/pre.png',
        text:'Precise'
    },
    {
        img: '../../../assets/imgs/footer/user.png',
        text:'User-Friendly'
    },
])

</script>

<template>
  <div class="footerBox-top">
    <div class='box'>
        <div class='item'>
            <img  src="../../../assets/imgs/footer/ai.png" alt=""/>
            <span> AI-Driven </span>
        </div>
        <div class='item'>
            <img  src="../../../assets/imgs/footer/pre.png" alt=""/>
            <span> Precise </span>
        </div>
        <div class='item'>
            <img src="../../../assets/imgs/footer/user.png" alt=""/>
            <span> User-Friendly </span>
        </div>
    </div>

  </div>
</template>

<style lang="less" scoped>
.footerBox-top {
  max-width: 1200px;
  margin: 0 auto;
  background: rgb(0, 0, 0);
    .box {
        justify-content: space-between;
        align-items: center;
        padding: 20px 79px;
        display: flex;
        .item {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            img {
                width: 50px;
                height: 50px;
                margin-bottom: 20px;
            }
            span {
                color: #fff;
                font-size: 14px;
                line-height: 19px;
                text-decoration: underline;
            }
        }
    }
}
</style>
